







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import Detail from './detail.vue'
@Component({
    components: {
        LsDialog,
        Detail
    }
})
export default class LinkSelect extends Vue {
    $refs!: { dialog: any }
    @Prop({ default: () => ({}) }) value!: any
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: 'mobile' }) client!: string
    link: any = {}

    get linkName() {
        const { type, name, params } = this.value
        if (!name) {
            return ''
        }
        switch (type) {
            case 'custom':
                return params.url
            default:
                return `${name}${
                    params && params.name ? '(' + params.name + ')' : ''
                }`
        }
    }

    @Watch('value', { immediate: true })
    valueChange(val: any) {
        this.link = JSON.parse(JSON.stringify(val))
    }
    onConfirm() {
        const { type, params, path } = this.link

        switch (type) {
            case 'shop':
                if (!path) {
                    this.$message.warning('请选择商城页面')
                    return
                }
                break
            case 'goods':
                if (!params.name) {
                    this.$message.warning('请选择商品')
                    return
                }
                break
            case 'seckill':
                if (!params.name) {
                    this.$message.warning('请选择秒杀商品')
                    return
                }
                break
            case 'team':
                if (!params.name) {
                    this.$message.warning('请选择拼团商品')
                    return
                }
                break
            case 'marking':
                if (!path) {
                    this.$message.warning('请选择营销页面')
                    return
                }
                break
            case 'draw':
                if (!params.name) {
                    this.$message.warning('请选择幸运抽奖')
                    return
                }
                break
            case 'category':
                if (!params.name) {
                    this.$message.warning('请选择分类')
                    return
                }
                break
            case 'custom':
                if (!params.url) {
                    this.$message.warning('请输入自定义链接')
                    return
                }
                break

            case 'page':
                if (!params.name) {
                    this.$message.warning('请选择微页面')
                    return
                }
                break
        }
        this.$refs.dialog.close()
        this.$emit('input', this.link)
    }
    handleClear() {
        this.$emit('input', {})
    }
}

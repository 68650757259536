
















import { apiLinkList, apiPcLinkList } from '@/api/app'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class Shop extends Vue {
    @Prop() value!: any
    @Prop() type!: string
    @Prop({ default: 'mobile' }) client!: string
    linkList = []

    get link() {
        return this.value
    }
    set link(val) {
        this.$emit('input', val)
    }

    getLists() {
        const link =
            this.client == 'mobile'
                ? apiLinkList({
                      type: this.type
                  })
                : apiPcLinkList({
                      type: this.type
                  })
        link.then(res => {
            this.linkList = res
        })
    }
    created() {
        this.getLists()
    }
}

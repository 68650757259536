





































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class AttributeTabs extends Vue {
    @Prop() title!: string
    active = 'content'
}























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class Shop extends Vue {
    @Prop() value!: any

    get params() {
        return this.value
    }
    @Watch('params', { deep: true })
    paramsChange(val: any) {
        this.$emit('input', val)
    }
}
